import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as firebase from 'firebase/app';

@Component({
  selector: 'change-email',
  templateUrl: 'change-email.html',
})
export class ChangeEmaildialog implements OnInit {
    public ceForm: FormGroup;
    currpass: string;
    newemail: string;
    entotp: string;

    reCaptchaVerifier!: any;
    verify: any;

    
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  constructor( private fb: FormBuilder, private afs: AngularFirestore, 
    public snackBar: MatSnackBar,  
    public afAuth: AngularFireAuth,
    
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ChangeEmaildialog>) {
      if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }

  Form() {
    this.ceForm = this.fb.group({
        currentpassword: ['',[Validators.required, Validators.minLength(3)]],
        newemailid: ['',[Validators.required, Validators.minLength(3)]],
        enterotp: ['',[Validators.required, Validators.minLength(3)]],
    })
  }
  get currentpassword() {
    return this.ceForm.get('currentpassword');
  }
  get newemailid() {
    return this.ceForm.get('newemailid');
  }
  get enterotp() {
    return this.ceForm.get('enterotp');
  }

    ngOnInit() {
        this.Form();
        this.currpass='';
        this.newemail='';
        this.entotp='';

        this.getOTP();
    }


    getOTP() {
        this.afAuth.authState.subscribe(user=>{
            if(user){
        //     this.currentcustid=firebase.auth().currentUser.uid;
        // var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
        // //if phone number
        // if(phoneRegex.test(this.usermobile)){
    
        this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'sign-in-button',
          {
            size: 'invisible',
          }
        );
        console.log(this.reCaptchaVerifier);
    
        firebase
          .auth()
          .signInWithPhoneNumber(firebase.auth().currentUser.phoneNumber, this.reCaptchaVerifier)
          .then((confirmationResult) => {
            localStorage.setItem(
              'verificationId',
              JSON.stringify(confirmationResult.verificationId)
            );
            // this.ngZone.run(() => {
            //   this.router.navigate(['/code']);
            // });
          })
          .catch((error) => {
            // console.log(error.message);
            // alert(error.message);
            this.openSnackBar(error.message,"OK");
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          
        // }
        // else{
        //   this.usermobilefoc.nativeElement.focus();
        //   this.openSnackBar("Please enter valid Mobile number","OK");
        // }
    }
})
      }

changeemailfunc(){
    if(this.entotp!='' && this.newemail!='' && this.newemail!='' && this.entotp!=''){
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;;
        if(emailRegex.test(this.newemail)){
          if(this.currpass.length>=6){
        console.log(this.entotp)
      this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
  
      var credential = firebase.auth.PhoneAuthProvider.credential(
        this.verify,
        this.entotp
      );
  
       console.log(credential);
  
      // this.SignUpEmailPass(this.emailinput,this.passwordinput)
      firebase
        .auth()
        .signInWithCredential(credential)
        .then((response) => {
          console.log(response);
          localStorage.setItem('user_data', JSON.stringify(response));
          //this.SignUpEmailPass(this.emailinput,this.passwordinput)
  
          const user = firebase.auth().currentUser;
    
  user.updateEmail(this.newemail).then(() => {
    user.updatePassword(this.currpass).then(() => {
      
        this.afs.collection("users").doc(user.uid).update({
            email: this.newemail
        }).then(()=>{
            this.openSnackBar('Email address updated successfully', 'OK')
        });
    })
  })
  
        })
        .catch((error) => {
          // console.log(error);
          // alert(error.message);
          this.openSnackBar(error.message,"OK");
        });
      }else{
        this.openSnackBar("Password must be atleast 6 characters","OK");
      }
      }else{
        this.openSnackBar("Invalid Email","OK");
      }
    }else{
      this.openSnackBar("Form Invalid","OK");
    }
}



  onConfirmClick(): void {
      this.changeemailfunc();
   // this.dialogRef.close(true);
  }

  
}