import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-productcategory',
  templateUrl: './productcategory.component.html',
  styleUrls: ['./productcategory.component.css'],

})
export class ProductcategoryComponent implements AfterViewInit, OnInit {
  firstFormGroup: FormGroup;

  showMainContent:Boolean=true;
  selectedOptions: string[] = [];

  categorycollection: { index:any, category: any }[]= [];


  constructor(private afs: AngularFirestore, public snackBar: MatSnackBar, private _formBuilder: FormBuilder, private route: ActivatedRoute, public router: Router) {
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  ngOnInit() {

    this.categorycollection= 
      [{index:'AH', category: "Air Hockey"},
      {index:'AR', category: "Archery"},
      {index:'AL', category: "Athletics"},
      {index:'BD', category: "Badminton"},
      {index:'BB', category: "Baseball"},
      {index:'BK', category: "Basketball"},
      {index:'BT', category: "Boating"},
      {index:'BM', category: "Boomerang"},
      {index:'BX', category: "Boxing"},
      {index:'BL', category: "Bowling"},
      {index:'CH', category: "Camping & Hiking"},
      {index:'CM', category: "Carrom"},
      {index:'CH', category: "Chess"},
      {index:'CR', category: "Cricket"},
      {index:'CL', category: "Climbing"},
      {index:'CY', category: "Cycling"},
      {index:'DR', category: "Darts"},
      {index:'DT', category: "Discus Throw"},
      {index:'DV', category: "Diving"},
      {index:'FI', category: "Fishing"},
      {index:'FB', category: "Football"},
      {index:'GF', category: "Golf"},
      {index:'HB', category: "Handball"},
      {index:'HH', category: "Hobby Hunting"},
      {index:'HY', category: "Hockey"},
      {index:'HR', category: "Horse Riding"},
      {index:'JT', category: "Jumping Trainers"},
      {index:'KF', category: "Kite Flying"},
      {index:'NB', category: "Netball"},
      {index:'BS', category: "Other Ball Sports"},
      {index:'PL', category: "Pool"},
      {index:'RY', category: "Rugby"},
      {index:'RU', category: "Running"},
      {index:'SC', category: "Scooters"},
      {index:'SH', category: "Shooting"},
      {index:'SK', category: "Skating"},
      {index:'SA', category: "Sport Accessories"},
      {index:'SQ', category: "Squash"},
      {index:'SW', category: "Swimming"},
      {index:'VB', category: "Volleyball"},
      {index:'TT', category: "Table Tennis"},
      {index:'TE', category: "Tennis"},
      {index:'TB', category: "Throwball"},
    
    ]
   ;
  }
  ngAfterViewInit(){
    
  };

  seeproductfilter(prdct){
      //do your any operations
      //this.router.navigate([`productfilter2/mycat:${prdct}`]);


      this.router.navigate(
        ['/productfilter2'], 
        {
          //relativeTo: this.activatedRoute,
          // queryParams: { myParam: this.selectedBrand },
          queryParams: {myctParam:prdct} ,
          //queryParamsHandling: 'merge'
        });


  }
}
