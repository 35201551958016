import { Component,AfterViewInit, OnInit, ViewChild, Pipe, PipeTransform, ɵɵgetInheritedFactory } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'rxjs';
import { map } from 'rxjs/operators';
import { observable, of } from 'rxjs';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';

import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
// import {ConfirmationDialog} from './confirmation-dialog.component';

@Component({
  selector: 'app-productbuild',
  templateUrl: './productbuild.component.html',
  styleUrls: ['./productbuild.component.css']
})
export class ProductbuildComponent implements OnInit {

  public pcForm: FormGroup;
  prdkeyid: any;
  prdbrand: any;
  prdcategory: any;
  prdcollection: any;
  prcollection: any;
  filetot: number;
  prdcolor: any ="MC";
  prdsize: any;
  prdpurrate: any;
  prdcode: any;
  prdrate1: number;
  prdonlinerate1: number;
  prddeliverycharges1: number;
  prddeliverydays1: number;
  favoriteTag: string;
  tags: string[] = ['Trending', 'Popular', 'New', 'None'];
  favoriteGameType: string;
  gametypes: string[] = ['Internal', 'External'];
  favoriteAgeGroup: string;
  // agegroups: string[] = ['All', 'Men', 'Women', 'Boys', 'Girls', 'Kids'];
  agegroups: string[] = ['All'];


  enableUpdate: boolean = false;
  
  prdname: any;
  prdonlinecategory: any;
  prddescription: any;
  prdhighlights1: any;
  prdhighlights2: any;

  prdmodelno: any;
  prdhsncode: any;
  prdgst: number;
  prdigst: number;
  prdunit1: any=1;
  prdunit2: any="PC(S)";

  sizechartname: any;
  sizchrtname: any='';
 
  constructor(private _sanitizer: DomSanitizer, private afs: AngularFirestore, private fb: FormBuilder, 
    private dialog: MatDialog,
    private snackBar: MatSnackBar){ }

    openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
         duration: 2000,
         panelClass: ['snackbarcss'],
      });
      }
      
  brndcollection: any;
  ctrycollection: any;
 

  ngOnInit() {

    this.filetot= 0;

    this.Form();  

    this.favoriteTag=this.tags[3]; // set default radio button checked
    this.favoriteGameType=this.gametypes[0]; // set default radio button checked
    this.favoriteAgeGroup=this.agegroups[0]; // set default radio button checked

    this.brndcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdbrand='';
    this.prdcategory='';
  }

  
  // Reactive form
  Form() {
    this.pcForm = this.fb.group({
      productbrand: [''],
      productcategory: [''],
      productsizechartname: [''],
      productcode: [''],
      productcolor: [''],
      productimages: [''],
      purchaserate: [''],
      productsize: [''],

      productrate1: [''],
      productonlinerate1: [''],
      productdeliverycharges1: [''],
      productdeliverydays1: [''],

      productname: [''],
      productonlinecategory: [''],
      productdescription: [''],
      producthighlights1: [''],
      producthighlights2: [''],

      productmodelno: [{value:''}],
      producthsncode: [{value:''}],
      productgst: [{value:''}],
      productigst: [{value:''}],
      productunit1: [{value:''}],
      productunit2: [{value:''}],
    })
  }

  // Accessing form control using getters
  get productbrand() {
    return this.pcForm.get('productbrand');
  }
  get productcategory() {
    return this.pcForm.get('productcategory');
  }
  get productsizechartname() {
    return this.pcForm.get('productsizechartname');
  }
  get productcode() {
    return this.pcForm.get('productcode');
  }
  get productcolor() {
    return this.pcForm.get('productcolor');
  }
  get productimages() {
    return this.pcForm.get('productimages');
  }
  get purchaserate() {
    return this.pcForm.get('purchaserate');
  }
  get productsize() {
    return this.pcForm.get('productsize');
  }
  get productrate1() {
    return this.pcForm.get('productrate1');
  }
  get productonlinerate1() {
    return this.pcForm.get('productonlinerate1');
  }
  get productdeliverycharges1() {
    return this.pcForm.get('productdeliverycharges1');
  }
  get productdeliverydays1() {
    return this.pcForm.get('productdeliverydays1');
  }


  get productname() {
    return this.pcForm.get('productname');
  }
  get productonlinecategory() {
    return this.pcForm.get('productonlinecategory');
  }
  get productdescription() {
    return this.pcForm.get('productdescription');
  }
  get producthighlights1() {
    return this.pcForm.get('producthighlights1');
  }
  get producthighlights2() {
    return this.pcForm.get('producthighlights2');
  }

  get productmodelno() {
    return this.pcForm.get('productmodelno');
  }

get producthsncode() {
  return this.pcForm.get('producthsncode');
}

get productgst() {
  return this.pcForm.get('productgst');
}

get productigst() {
  return this.pcForm.get('productigst');
}

get productunit1() {
  return this.pcForm.get('productunit1');
}

get productunit2() {
  return this.pcForm.get('productunit2');
}



  changeBrand(br){
    this.ctrycollection = this.afs.collection('Brands', ref => ref.where('brandname', "==" , br)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );
  this.prdsize='';
  this.sizchrtname ='';
  this.prdcategory ='';
  this.resetproductdetails();
  //console.log(JSON.stringify(this.ctrycollection));
  }

  changeCategory(ct,br){
    //console.log(this.prdbrand);

    ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
    //console.log(ct);

    
    this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
      if(doc.data().categories){
        for(let i=0;i<doc.data().categories.length;i++){
          if(doc.data().categories[i].categoryname == ct){
            this.sizchrtname = doc.data().categories[i].sizechart
            //console.log(this.sizchrtname)
          }
          if(doc.data().categories[i].categoryname != ct){
          }
      }
      
    }
  })

    this.prcollection = this.afs.collection('Productbuild', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.prdcollection = this.afs.collection('Products', ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


this.resetproductdetails();
  }

// Reset form's values
ResetForm() {
  this.urls = [];
  this.filetot= 0;
  this.pcForm.reset();
}


submitProductbuildData() {
  this.add(this.pcForm.value.productid);
  //this.ResetForm();  // Reset form when clicked on reset button
  //this.resetproductdetails();
};


add(id) {
   let ids =this.afs.createId();
  this.afs.collection("Products").doc(ids).set({

    0:"0",
    KEYID:ids,
    brand:this.prdbrand,
    category:this.prdcategory.substr(this.prdcategory.indexOf(' ')+1),
    bcc:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')),
      images:this.urls, //images
      size:this.prdsize,
      color:this.prdcolor,
      // code:this.prdcolor+this.prdsize,
      code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
      prate:isNaN(Number(this.prdpurrate)) ? 0 : Number(this.prdpurrate),
      rate:isNaN(Number(this.prdrate1)) ? 0 : Number(this.prdrate1),
      onlinerate:isNaN(Number(this.prdonlinerate1)) ? 0 : Number(this.prdonlinerate1),
      deliverycharges:isNaN(Number(this.prddeliverycharges1)) ? 0 : Number(this.prddeliverycharges1),
      deliverydays:isNaN(Number(this.prddeliverydays1)) ? 0 : Number(this.prddeliverydays1),
      tag:this.favoriteTag,
      agegroup:this.favoriteAgeGroup,
      gametype:this.gametypes,

      productname:this.prdname,
      onlinecategory: (this.prdonlinecategory!=null) ? (this.prdonlinecategory.toUpperCase()):'',
      description: (this.prddescription!=null) ? (this.prddescription):'',
      highlights: (this.prdhighlights1!=null || this.prdhighlights2!=null ) ? (firebase.firestore.FieldValue.arrayUnion(this.prdhighlights1,this.prdhighlights2)):[],

      modelno: (this.prdmodelno!=null) ? (this.prdmodelno.toUpperCase()):'',
      hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
      gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
      igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
      unit1: Number(this.prdunit1),
      unit2: this.prdunit2,

      //newly added
      sizechart: String(this.sizchrtname).substring(0,2),
      //newly added

  }, { merge: true })
.then(()=>{
  this.openSnackBar("Product Added Successfully", 'OK')
})
    .catch((err) => {
      //console.log(err);
    })
   
  //this.update(id);
}


resetproductdetails(){
  this.prdname='';

  this.prddescription='';
  this.prdonlinecategory='';
  this.prdhighlights1='';
  this.prdhighlights2='';

  this.prdcolor='MC';
  this.prdsize='';
  this.prdmodelno='';
  this.prdhsncode='';
  this.prdgst=null;
  this.prdigst=null;
  this.prdrate1=null;
  this.prdonlinerate1=null;
  this.prddeliverycharges1=null;
  this.prddeliverydays1=null;
  this.favoriteTag=this.tags[3];
  this.favoriteAgeGroup=this.agegroups[0];
  this.favoriteGameType=this.gametypes[0];
  this.urls = [];
  this.filetot= 0;

  this.enableUpdate=false;

  this.prdpurrate='';
}


update(pid) {
  //console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct).where('code',"==",this.prdcode)).doc(pid).update({
    images:this.urls, //images
    size:this.prdsize,
    color:this.prdcolor,
    //code:this.prdcolor+this.prdsize,
    code:this.prdcategory.substr(0,this.prdcategory.indexOf(' ')) + this.prdcolor + this.prdsize,   //this.prdcategory.substr(0,this.prdcategory.indexOf(' '))); //removes text
    prate:isNaN(Number(this.prdpurrate)) ? 0 : Number(this.prdpurrate),
    rate:isNaN(Number(this.prdrate1)) ? 0 : Number(this.prdrate1),
    onlinerate:isNaN(Number(this.prdonlinerate1)) ? 0 : Number(this.prdonlinerate1),
    deliverycharges:isNaN(Number(this.prddeliverycharges1)) ? 0 : Number(this.prddeliverycharges1),
    deliverydays:isNaN(Number(this.prddeliverydays1)) ? 0 : Number(this.prddeliverydays1),
    tag:this.favoriteTag,
    agegroup:this.favoriteAgeGroup,
    gametype:this.gametypes,

    productname:this.prdname,
    onlinecategory: (this.prdonlinecategory!=null) ? (this.prdonlinecategory.toUpperCase()):'',
    description: (this.prddescription!=null) ? (this.prddescription):'',
    highlights: (this.prdhighlights1!=null || this.prdhighlights2!=null ) ? (firebase.firestore.FieldValue.arrayUnion(this.prdhighlights1,this.prdhighlights2)):[],

    modelno: (this.prdmodelno!=null) ? (this.prdmodelno.toUpperCase()):'',
    hsncode: (this.prdhsncode!=null) ? (this.prdhsncode.toUpperCase()):'',
    gst:isNaN(Number(this.prdgst)) ? 0 : Number(this.prdgst),
    igst:isNaN(Number(this.prdigst)) ? 0 : Number(this.prdigst),
    unit1:Number(this.prdunit1),
    unit2:this.prdunit2,

      //newly added
      sizechart: String(this.sizchrtname).substring(0,2),
      //newly added

  }).then(()=>{
    //this.ResetForm();
    //this.resetproductdetails();
    this.enableUpdate=false;
  })
}


read(pd,i) {  
  this.prdkeyid = pd.KEYID;
  this.prdbrand = pd.brand;
  this.prdcategory = pd.bcc+' '+pd.category;
  this.urls = pd.images;
  this.prdcode = pd.code;
  this.prdsize = pd.size;
  this.prdcolor = pd.color;
  this.prdpurrate = pd.prate;
  this.prdrate1 = pd.rate;
  this.prdonlinerate1 = pd.onlinerate;
  this.prddeliverycharges1 = pd.deliverycharges;
  this.prddeliverydays1 = pd.deliverdays;
  this.favoriteTag=pd.tag;
  this.favoriteAgeGroup=pd.agegroup;
  this.favoriteGameType=pd.gametype;

  this.prdname=pd.productname;
  this.prdonlinecategory=pd.onlinecategory;
  this.prddescription=pd.description;
  this.prdhighlights1= pd.highlights.length>0 ? pd.highlights[0] : '';
  this.prdhighlights2= pd.highlights.length>1 ? pd.highlights[1] : '';

  this.prdmodelno=pd.modelno;
  this.prdhsncode=pd.hsncode;
  this.prdgst=pd.gst;
  this.prdigst=pd.igst;
  this.prdunit1=pd.unit1;
  this.prdunit2=pd.unit2;
  
  this.filetot = this.urls.length;

}


delete(pd,i) {
  this.prdkeyid = pd.KEYID;
  //console.log(this.prdcode);
  let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
  this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).doc(this.prdkeyid).delete();
}




checkprd(){
  //console.log("hello");
    
  this.enableUpdate=false;

  if(this.prdbrand && this.prdcategory && this.prdcolor && this.prdsize){
    let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
    this.afs.collection('Products', ref => ref.where('brand', '==' , this.prdbrand).where('category', '==' , ct)
    .where('color', '==' , this.prdcolor).where('size', '==' , this.prdsize)).get().toPromise().then(querySnapshot => {
      if (querySnapshot.size > 0) {

        this.enableUpdate=true;

        //console.log("hello3");
        querySnapshot.forEach(doc => {
          this.prdkeyid = doc.data().KEYID;
          this.prdbrand = doc.data().brand;
          this.prdcategory = doc.data().bcc+' '+doc.data().category;
          this.urls = doc.data().images;
          this.prdcode = doc.data().code;
          this.prdsize = doc.data().size;
          this.prdcolor = doc.data().color;
          this.prdpurrate = doc.data().prate;
          this.prdrate1 = doc.data().rate;
          this.prdonlinerate1 = doc.data().onlinerate;
          this.prddeliverycharges1 = doc.data().deliverycharges;
          this.prddeliverydays1 = doc.data().deliverydays;
          this.favoriteTag=doc.data().tag;
          this.favoriteAgeGroup=doc.data().agegroup;
          this.favoriteGameType=doc.data().gametype;

          this.prdname=doc.data().productname;
          this.prdonlinecategory=doc.data().onlinecategory;
          this.prddescription=doc.data().description;
          this.prdhighlights1= doc.data().highlights.length>0 ? doc.data().highlights[0] : '';
          this.prdhighlights2= doc.data().highlights.length>1 ? doc.data().highlights[1] : '';

          this.prdmodelno=doc.data().modelno;
          this.prdhsncode=doc.data().hsncode;
          this.prdgst=doc.data().gst;
          this.prdigst=doc.data().igst;
          this.prdunit1=doc.data().unit1;
          this.prdunit2=doc.data().unit2;

          this.filetot = this.urls.length;
        
          })
      }
    })
}
}

urls = new Array<string>();
detectFiles(event) {
  this.urls = [];
  let files = event.target.files;
  if (files) {
    for (let file of files) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push(e.target.result);
        //console.log(this.urls);
        this.filetot = this.urls.length;

      }
      reader.readAsDataURL(file);
    }
  }
}

deleteImage(key){
  this.urls.splice(key,1);
  this.filetot = this.urls.length;
  //console.log(this.urls);
}







// openDialog(pd,i) {
//   const dialogRef = this.dialog.open(ConfirmationDialog,{
//     data:{
//       message: 'Are you sure you want to delete this product permanently?',
//       buttonText: {
//         ok: 'Yes',
//         cancel: 'No'
//       }
//     }
//   });

//   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
//     if (confirmed) {

//       this.prdkeyid = pd.KEYID;
//   //console.log(this.prdcode);
//   let ct=this.prdcategory.substr(this.prdcategory.indexOf(' ')+1);
//   this.afs.collection("Products", ref => ref.where('brand', "==" , this.prdbrand).where('category', "==" , ct)).doc(this.prdkeyid).delete();

//       this.snackBar.open('Product Deleted Successfully.', 'OK', {
//         duration: 2000,
//       });
//     }
//   });
// }

}