import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import {Globals} from '../../../assets/shared/globals'
import * as firebase from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {
  redirectURL: any;

  reCaptchaVerifier!: any;
  useremail: string='';
  userpassword: string='';
  otpinput: string='';
  verify: any;

  otpfield: any='hide';
  isotprequested: boolean = false;

  public emForm: FormGroup;

   @ViewChild('useremailid', {static: true}) useremailfoc: any;

  constructor(
    public authService: AuthService, public globals: Globals, private fb: FormBuilder,
    public router: Router,
    public ngZone: NgZone, 
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
  ) { 
    let params = this.route.snapshot.queryParams;
    if (params['redirectURL']) {
        this.redirectURL = params['redirectURL'];
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  Form() {
    this.emForm = this.fb.group({
      emailinputcntrl: [''],
    })
  }
  get emailinputcntrl() {
    return this.emForm.get('emailinputcntrl');
  }
  ngOnInit() {  this.Form();  
       this.useremail='';
}
isNumber(val):boolean{return typeof val === 'number';}
signingetOTP(){
  // var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
     var phoneRegex = /^\d{10}$/;
    //if phone number
    if(phoneRegex.test(this.useremail)){

      this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
        }
      );
      console.log(this.reCaptchaVerifier);
  
      console.log(this.useremail);
      firebase
        .auth()
        .signInWithPhoneNumber("+91"+this.useremail, this.reCaptchaVerifier)
        .then((confirmationResult) => {
          localStorage.setItem(
            'verificationId',
            JSON.stringify(confirmationResult.verificationId)
          );
          // this.ngZone.run(() => {
          //   this.router.navigate(['/code']);
          // });
        })
        .catch((error) => {
          // console.log(error.message);
          // alert(error.message);
          //this.openSnackBar(error.message,"OK");

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })

      this.otpfield='show';

      this.isotprequested=true;
      this.disableemailinput();

    }
    else{
      this.useremailfoc.nativeElement.focus();
      this.openSnackBar("Please enter valid Mobile number","OK");
    }
}

SignInphn(){
  if(this.otpinput!=''){
    console.log(this.otpinput);
    this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
    var credential = firebase.auth.PhoneAuthProvider.credential(
      this.verify,
      this.otpinput
    );

    console.log(credential);
    firebase
      .auth()
      .signInWithCredential(credential)
      .then((response) => {
        console.log(response);
        localStorage.setItem('user_data', JSON.stringify(response));
        this.ngZone.run(() => {
          if (this.redirectURL) {        
            this.router.navigateByUrl(this.redirectURL,)
                .catch(() => this.router.navigate(['/home']))
        } else {
        
            this.router.navigate(['/home'])
        }
    
        });
      })
      .catch((error) => {
        //console.log(error);
        //alert(error.message);
        //this.openSnackBar(error.message,"OK");
      });    
    }else{
      this.openSnackBar("Enter OTP","OK");
    }
}

disableemailinput(){
  if(this.isotprequested==true){
    this.emForm.controls['emailinputcntrl'].disable()
  }
}
  enableemailinput(){
    //if(this.isotprequested==true){
      this.otpfield='hide';
      this.emForm.controls['emailinputcntrl'].enable();
      this.isotprequested=false
    //}
}
}