import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.css']
})
export class MyordersComponent implements OnInit {
  currentcustid: string;
  orderedidary: any;
  productdetailsary: any;
  paymentdetailsary: any;

  constructor( private fb: FormBuilder, public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private afs: AngularFirestore, private http:HttpClient, public router: Router) {
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  ngOnInit() {
    this.orderedidary=[];
    this.productdetailsary=[];
    this.paymentdetailsary=[];

    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      this.afs.collection('users',ref=>ref.where('uid','==',this.currentcustid)).get().toPromise()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            this.orderedidary=(doc.data().ordered) ? doc.data().ordered : [];
            if(this.orderedidary.length>0){
for(let i=0;i<this.orderedidary.length;i++){

                  this.afs.collection("Orders").doc(this.orderedidary[i]).collection("productdetails").get().toPromise().then(querySnapshot => {
     if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
this.productdetailsary.push((doc.data()))
        })
      
     }
    })

    this.afs.collection("Orders").doc(this.orderedidary[i]).collection("paymentdetails").get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
         querySnapshot.forEach(doc => {
 this.paymentdetailsary.push((doc.data()))
         })
       
      }
     })

  }
            }
          })
        }
      })
    }
    else{
      this.router.navigate(
        ['sign-in'], 
        {
          queryParams: { redirectURL: this.router.url } 
        });
    }
    })
  }

  ratereviews(prdid){
    this.router.navigate(
      ['ratingsandreviews'], 
      {
        queryParams: {myprParam: JSON.stringify(prdid) } ,
        // queryParamsHandling: 'merge'
      });
  }

  navigatevieworderdetails(oid){
    this.router.navigate(
      ['orderdetails'], 
      {
        queryParams: {myorParam: JSON.stringify(oid) } ,
        // queryParamsHandling: 'merge'
      });
  }
}
