// 'use strict';

// export const sep='/';
// export const version: string="22.2.2";  


import { Injectable } from "@angular/core";

@Injectable()
export class Globals{
   companyname: string ="ADVANCED COMPUTERS";
   address1L1: string="ADVANCED COMPUTERS";
   address1L2: string="3607/25, K.A.S Complex,";
   address1L3: string="Near Team Hospital,";
   address1L4: string="Pudukkottai, 622001";
   address2L1: string="ADVANCED COMPUTERS";
   address2L2: string="3607/25, K.A.S Complex,";
   address2L3: string="Near Team Hospital,";
   address2L4: string="Pudukkottai, 622001";
   GSTIN: string="33AKCPM109J1ZC";
   mobile1:string="+91 9842367336";
   mobile2:string="+91 9842367333";
   mobile3:string="+91 9842367000";
}
