import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, timestamp } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-allrecentlyviewed',
  templateUrl: './allrecentlyviewed.component.html',
  styleUrls: ['./allrecentlyviewed.component.css']
})
export class AllrecentlyviewedComponent implements OnInit {

  currentcustid: string;

  loadrecentlyviewed: any[];
  loadrecentlyviewedproducts: any[];
  recentlyviewedlength: number =0;

  constructor(public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private afs: AngularFirestore, private changeDetection: ChangeDetectorRef, public router: Router) {
 
    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
    }
    })
    
       }
       openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
           duration: 2000,
           panelClass: ['snackbarcss'],
        });
        }

  ngOnInit() {

        // this.activatedRoute.params.subscribe(params => {
        //   this.clickedprdid = params['productid'];
        //   });
    
    
        this.afAuth.authState.subscribe(user=>{
          if(user){
          this.currentcustid=firebase.auth().currentUser.uid;
          this.recentlyviewedlength=0;
          this.loadrecentlyviewed =[];
    
          this.afs.collection("users", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                this.recentlyviewedlength=doc.data().recentlyviewed ? doc.data().recentlyviewed.length : 0
                this.loadrecentlyviewed=doc.data().recentlyviewed
              })
            }
          }).then(()=>{
            this.loadrecentlyviewedproducts =[];
            for(let i=0;i < this.loadrecentlyviewed.length;i++){
            this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadrecentlyviewed[i] )).get().toPromise().then(querySnapshot => {
              if (!querySnapshot.empty) {
                querySnapshot.forEach(doc => {
                  this.loadrecentlyviewedproducts.push({'KEYID':doc.data().KEYID,
                  'bcc':doc.data().bcc,
                  'brand':doc.data().brand,
                  'category':doc.data().category,
                  'code':doc.data().code,    
                  'rate':doc.data().rate,             
                  'onlinerate':doc.data().onlinerate,                                 
                  'images':doc.data().images,
                  'description':doc.data().description,
                  'color':doc.data().color,
                 'size':doc.data().size,
                 'sizechart':doc.data().sizechart,
                 'wishlist':doc.data().wishlist!=undefined ? (doc.data().wishlist.includes(this.currentcustid) ? true : false) : false,
                 'numberofratings':doc.data().numberofratings ? doc.data().numberofratings: 0,
                 'totalstars':doc.data().totalstars ? doc.data().totalstars: 0 ,
                })
                })
              }
          })
            }
          })
        }
        else{
          this.router.navigate(
            ['sign-in'], 
            {
              queryParams: { redirectURL: this.router.url } 
            });
        }
          })
    
  }
  updateWishlist(o){

    this.afAuth.authState.subscribe(user=>{
      if(user){
    this.currentcustid=firebase.auth().currentUser.uid;

    console.log(o.code,this.currentcustid)
    if(o.wishlist==false){
      this.afs.collection('Products').doc(o.KEYID).set({
        wishlist:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
      },{merge:true}).then(()=>{
        this.afs.collection('users').doc(this.currentcustid).set({
          wishlist:firebase.firestore.FieldValue.arrayUnion(o.KEYID)
        },{merge:true})
      })
      //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=true;
    }
    if(o.wishlist==true){
      this.afs.collection('Products').doc(o.KEYID).set({
        wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
      },{merge:true}).then(()=>{
        this.afs.collection('users').doc(this.currentcustid).set({
          wishlist:firebase.firestore.FieldValue.arrayRemove(o.KEYID)
        },{merge:true})
      })
      //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=false;
    }
  }
  else{
    this.router.navigate(
      ['sign-in'], 
      {
        queryParams: { redirectURL: this.router.url } 
      });
  }
  })
    // this.currentcustid=firebase.auth().currentUser.uid;

    // console.log(o.code,this.currentcustid)
    // if(o.wishlist==false){
    //   this.afs.collection('Products').doc(o.KEYID).set({
    //     wishlist:firebase.firestore.FieldValue.arrayUnion(this.currentcustid)
    //   },{merge:true}).then(()=>{
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       wishlist:firebase.firestore.FieldValue.arrayUnion(o.KEYID)
    //     },{merge:true})
    //   })
    //   //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=true;
    // }
    // if(o.wishlist==true){
    //   this.afs.collection('Products').doc(o.KEYID).set({
    //     wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
    //   },{merge:true}).then(()=>{
    //     this.afs.collection('users').doc(this.currentcustid).set({
    //       wishlist:firebase.firestore.FieldValue.arrayRemove(o.KEYID)
    //     },{merge:true})
    //   })
    //   //this.groupproducts[this.groupproducts.findIndex(item=>item.KEYID === this.currentprdkeyid)].wishlist=false;
    // }
  }
  productclicked(prdid){
    this.router.navigate(
      ['productdescription'], 
      {
        queryParams: {myprParam: JSON.stringify(prdid) } ,
        // queryParamsHandling: 'merge'
      });
  }
}
