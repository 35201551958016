import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/app';
import { map, take } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import { id } from 'date-fns/locale';
import {Globals} from '../../assets/shared/globals'

declare var Razorpay: any; 

@Component({
  selector: 'app-buynow',
  templateUrl: './buynow.component.html',
  styleUrls: ['./buynow.component.css']
})
export class BuynowComponent implements OnInit {
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  //-----------------MAFORM START------------------
  public maForm: FormGroup;

  fname: string;
  mnumber: string;

  pcode: string;
  local: string;

  adress: string;

  dist: string;
  stte: string;

  lmark: string;
  altphone: string;

  addrtype:string;
  // currentcustid: string;
  // loadaddresses: any;

KEYID: string;

savebtnvisible: boolean;
maFormvisible: boolean;
//-----------------MAFORM END------------------

  selectedaddress: any;
  loadaddresses: any;
  currentcustid: string;

  loadbuynowproduct: any[]= []; //declare before constructor

  buynowcalculations: any[]= []; //declare before constructor
  ratesum: number;
  onlineratesum: number;
  discountsum: number;
  deliverychargesum: number;
  deliverydaysmax: number;
  totalamount: number;

  deliverydate: Date;
  currentcustphone: string='';
  currentcustname: string='';
  currentcustemail: string='';

  date2= moment().format('DD-MM-YYYY');
  currentorderid: string;
  qntylabel: number=1;

  constructor( public globals: Globals, private ren: Renderer2, private fb: FormBuilder, public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private afs: AngularFirestore, private http:HttpClient, public router: Router) {
    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      // this.currentcustname=firebase.auth().currentUser.displayName;
      this.currentcustname=(firebase.auth().currentUser.displayName !=null) ? firebase.auth().currentUser.displayName : 'MY ACCOUNT';
      this.currentcustphone=firebase.auth().currentUser.phoneNumber;
      this.currentcustemail=firebase.auth().currentUser.email;
    }
    })

  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  addorreplace(array, item){
    const i=array.findIndex(_item => _item.KEYID===item.KEYID);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplace2(array, item){
    const i=array.findIndex(_item => _item.prdid===item.prdid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   clickedprdid: any;

  ngOnInit() {
    

    this.route.queryParamMap.subscribe((params: any) => {
      this.clickedprdid = String(JSON.parse(params.params.myprParam));
      console.log(this.clickedprdid)
      });  

    this.Form();  

    this.fname='';
    this.mnumber='';

    this.pcode='';
    this.local='';

    this.adress='';

    this.dist='';
    this.stte='';

    this.lmark='';
    this.altphone='';

    this.addrtype="home";

    this.KEYID='';


    this.savebtnvisible=true;
    this.maFormvisible=false;

    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      
      this.loadaddresses =[];
    this.loadaddresses=this.afs.collection("users").doc(this.currentcustid).collection("addresses",ref=>ref.orderBy('timestamp','asc')).snapshotChanges()
      .pipe(
        map(actions => actions.map(a => a.payload.doc.data()))
      );

      this.selectedaddress =[];

    this.afs.collection("users").doc(this.currentcustid).collection("addresses", ref=> ref.where("primaryaddress","==",true)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.selectedaddress=doc.data();
        })
      }
    })



  this.loadbuynowproduct =[];
  this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.clickedprdid )).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.addorreplace(this.loadbuynowproduct,doc.data());
        this.addorreplace(this.buynowcalculations,{'KEYID':doc.data().KEYID,'rate':doc.data().rate,'onlinerate':doc.data().onlinerate,'qnty':Number(1),
        'discount': (doc.data().rate > doc.data().onlinerate) ? (doc.data().rate - doc.data().onlinerate) : 0,
        'deliverycharges': (doc.data().deliverycharges) ? (doc.data().deliverycharges) : 0,
        'deliverydays': (doc.data().deliverydays) ? Number(doc.data().deliverydays) : (5) })
       

          this.ratesum=Number(this.buynowcalculations.map(o => (o.rate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.onlineratesum=Number(this.buynowcalculations.map(o => (o.onlinerate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.discountsum=Number(this.buynowcalculations.map(o => (o.discount*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.deliverychargesum=Number(this.buynowcalculations.map(o => (o.deliverycharges)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.deliverydaysmax=Number(this.buynowcalculations.map(o => (o.deliverydays)).reduce((a,c)=> {return Math.max(a,c)}).toFixed(0));
          this.deliverydate=new Date(Date.now() +  (24*3600*1000*this.deliverydaysmax) );
          this.totalamount=Number((this.ratesum - this.discountsum + this.deliverychargesum).toFixed(0));
      })
    }
})
  

}
else{
  this.router.navigate(
    ['sign-in'], 
    {
      queryParams: { redirectURL: this.router.url } 
    });
}
  })
  }

    updateCheckedOptions(a,i){
      this.afs.collection("users").doc(this.currentcustid).collection("addresses", ref=> ref.where("KEYID","==",a.KEYID)).get().toPromise().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            this.selectedaddress=doc.data();
          })
        }
      })
    }

    
  qntyminusclicked(pid){
    this.qntylabel= this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == pid))].qnty-1
      console.log(this.buynowcalculations)
      this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == pid))].qnty = this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == pid))].qnty-1
      
      this.ratesum=Number(this.buynowcalculations.map(o => (o.rate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.onlineratesum=Number(this.buynowcalculations.map(o => (o.onlinerate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.discountsum=Number(this.buynowcalculations.map(o => (o.discount*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverychargesum=Number(this.buynowcalculations.map(o => (o.deliverycharges)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverydaysmax=Number(this.buynowcalculations.map(o => (o.deliverydays)).reduce((a,c)=> {return Math.max(a,c)}).toFixed(0));
      this.deliverydate=new Date(Date.now() +  (24*3600*1000*this.deliverydaysmax) );
      this.totalamount=Number((this.ratesum - this.discountsum + this.deliverychargesum).toFixed(0));
    
      console.log(this.buynowcalculations)
      }
  qntyplusclicked(pid){
    this.qntylabel= this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == pid))].qnty+1
      this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == pid))].qnty = this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == pid))].qnty+1
      
      this.ratesum=Number(this.buynowcalculations.map(o => (o.rate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.onlineratesum=Number(this.buynowcalculations.map(o => (o.onlinerate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.discountsum=Number(this.buynowcalculations.map(o => (o.discount*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverychargesum=Number(this.buynowcalculations.map(o => (o.deliverycharges)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverydaysmax=Number(this.buynowcalculations.map(o => (o.deliverydays)).reduce((a,c)=> {return Math.max(a,c)}).toFixed(0));
      this.deliverydate=new Date(Date.now() +  (24*3600*1000*this.deliverydaysmax) );
      this.totalamount=Number((this.ratesum - this.discountsum + this.deliverychargesum).toFixed(0));
    
      console.log(this.buynowcalculations)
  }



     //-----------------MAFORM START------------------
 // Reactive form
 Form() {
  this.maForm = this.fb.group({
    fullname: ['',[Validators.required, Validators.minLength(3)]],
    mobilenumber: ['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],

    pincode:  ['',[Validators.required, Validators.minLength(6)]],
    locality: [''],

    address:  ['',[Validators.required, Validators.minLength(10)]],

    district: ['',[Validators.required, Validators.minLength(1)]],
    state: ['',[Validators.required, Validators.minLength(1)]],

    landmark: [''],
    alternatephone: [''],

    addresstype: [''],
  })
}

    get fullname() {
      return this.maForm.get('fullname');
    }
    get mobilenumber() {
      return this.maForm.get('mobilenumber');
    }

    get pincode() {
      return this.maForm.get('pincode');
    }
    get locality() {
      return this.maForm.get('locality');
    }

    get address() {
      return this.maForm.get('address');
    }

    get district() {
      return this.maForm.get('district');
    }
    get state() {
      return this.maForm.get('state');
    }

    get landmark() {
      return this.maForm.get('landmark');
    }
    get alternatephone() {
      return this.maForm.get('alternatephone');
    }

    get addresstype() {
      return this.maForm.get('addresstype');
    }
    submitmaData() {

      if(this.maForm.valid){
      this.afs.collection('users').doc(this.currentcustid).collection("addresses").get().toPromise().then(querySnapshot => {
          if(querySnapshot.size < 4){
        this.afs.collection('users').doc(this.currentcustid).collection("addresses", ref => ref.where("address", "==", this.maForm.value.address).where("fullname", "==", this.maForm.value.fullname)).get().toPromise().then(querySnapshot => {
        if (querySnapshot.empty) {
            this.maadd();
            this.deliveryhere();
        }
        else{
          querySnapshot.forEach(doc => {
            this.KEYID=doc.data().KEYID;
          this.updateaddress();
          })
        }
      })
          }
          else{
            this.openSnackBar("You can't save more than 4 addresses", 'OK')
          }
    });
      }
      else{
        console.log("form inVlid")
      }
      //this.ResetForm();  // Reset form when clicked on reset button
      //this.resetproductdetails();
    };

    maadd() {

      let ids =this.afs.createId();
      this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(ids).set({
        KEYID:ids,
        fullname: this.fname.toUpperCase(),
        mobile: this.mnumber,
        pincode: this.pcode,
        locality: this.local,
        address: this.adress,
        district: this.dist,
        state: this.stte,
        landmark: this.lmark,
        alternatephone: this.altphone,
        addresstype: this.addrtype,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        primaryaddress: false
      }).then(()=>{

        this.afs.collection('users').doc(this.currentcustid).collection("addresses").get().toPromise().then(querySnapshot => {
          if(querySnapshot.size == 1){
            querySnapshot.forEach(doc => {
              this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(String(doc.data().KEYID)).update({
                primaryaddress: true
            });
            })
          }
            this.openSnackBar('New Address Added Successfully', 'OK')
            this.clear();
            //newly added
            this.deliveryhere();
            //newly added
      })
        })
            .catch((err) => {
          console.log(err);
        })

    }


    refreshpincode(pcode){
      if(pcode.length==6){
      this.http.get('https://api.postalpincode.in/pincode/'+pcode).subscribe((res)=>{
      //console.log(res);
      if(res[0].Status=="Success"){
        this.dist=res[0].PostOffice[0].District;
        this.stte=res[0].PostOffice[0].State;
      }
      else{
        console.log("Invalid Pincode");
        this.dist='';
        this.stte='';
      }
    })
  }
  else{
    //console.log("Incomplete Pincode");
        this.dist='';
        this.stte='';
  }
  }

addanewaddressclick(){
  if(this.currentcustname==''){
    this.openSnackBar('Please Login first to proceed', 'OK')
  }
  if(this.currentcustname!=''){
this.maFormvisible=true;
  }
}
editaddress(a){
    this.maFormvisible=true;

  this.savebtnvisible=false;

      this.fname = a.fullname;
      this.mnumber=a.mobile;
      this.pcode= a.pincode,
      this.local= a.locality;
      this.adress = a.address;
      this.dist = a.district;
      this.stte = a.state;
      this.lmark = a.landmark;
      this.altphone = a.alternatephone;
      this.addrtype = a.addresstype;

      this.KEYID=a.KEYID;
      }
updateaddress(){
      this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(this.KEYID).update({
        fullname: this.fname.toUpperCase(),
        mobile: this.mnumber,
        pincode: this.pcode,
        locality: this.local,
        address: this.adress,
        district: this.dist,
        state: this.stte,
        landmark: this.lmark,
        alternatephone: this.altphone,
        addresstype: this.addrtype,
        timestamp:firebase.firestore.FieldValue.serverTimestamp()
      }).then(()=>{
                this.openSnackBar('Address Updated Successfully', 'OK')
                this.clear();
                //newly added
                this.deliveryhere();
                //newly added
      })

      this.selectedaddress =[];
      this.afs.collection("users").doc(this.currentcustid).collection("addresses", ref=> ref.where("primaryaddress","==",true)).get().toPromise().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            this.selectedaddress=doc.data();
          })
        }
      })

}
deleteaddress(id){
  console.log(id)
      this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(id).delete().then(() => {
      console.log('deleted');
    })
}

clear(){
  this.maFormvisible=false;

  this.savebtnvisible=true;

      // this.fname = '';
      // this.mnumber= '';
      // this.pcode= '';
      // this.local= '';
      // this.adress = '';
      // this.dist = '';
      // this.stte = '';
      // this.lmark = '';
      // this.altphone = '';
      // this.addrtype = 'home';

         this.maForm.reset();
         this.addrtype = 'home';
         this.KEYID= '';
}
//-----------------MAFORM END------------------


logineditvisible:boolean=false;
deliveryeditvisible:boolean=true;
ordersummaryeditvisible:boolean=false;
paymenteditvisible:boolean=false;
loginconfirmvisible:boolean=true;
deliveryconfirmvisible:boolean=false;
ordersummaryconfirmvisible:boolean=false;
loginvisible:boolean=false;
deliveryvisible:boolean=false;
ordersummaryvisible:boolean=true;
paymentvisible:boolean=true;


continuecheckout(){
  this.logineditvisible=false;
  this.loginconfirmvisible=true;
  this.deliveryvisible=false;
  this.deliveryeditvisible=true;
}
loginchange(){
  
  this.logineditvisible=true;
  this.deliveryeditvisible=false;
  this.ordersummaryeditvisible=false;
  this.paymenteditvisible=false;

  this.deliveryvisible=true;
  this.ordersummaryvisible=true;
  this.paymentvisible=true;

  this.loginconfirmvisible=false;
  this.deliveryconfirmvisible=false;
  this.ordersummaryconfirmvisible=false;
}
deliveryhere(){
  this.deliveryeditvisible=false;
  this.deliveryconfirmvisible=true;
  this.ordersummaryvisible=false;
  this.ordersummaryeditvisible=true;

}
deliveryaddresschange(){
  this.deliveryeditvisible=true;
  this.ordersummaryeditvisible=false;
  this.paymenteditvisible=false;

  this.ordersummaryvisible=true;
  this.paymentvisible=true;


  this.deliveryconfirmvisible=false;
  this.ordersummaryconfirmvisible=false;
}

continue(){
  this.ordersummaryeditvisible=false;
  this.ordersummaryconfirmvisible=true;
  this.paymentvisible=false;
  this.paymenteditvisible=true;
}
ordersummarychange(){
  this.ordersummaryeditvisible=true;
  this.paymenteditvisible=false;

  this.paymentvisible=true;

  this.ordersummaryconfirmvisible=false;

}




// rzpl;
// pay(){
//   this.rzpl=new Razorpay(
//     {
//       "key": "rzp_test_vmkyCOWphmctuL", // Enter the Key ID generated from the Dashboard
//       "amount": (10)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
//       "currency": "INR",
//       "name": "Altius Sportwear",
//       "description": "Online Store",
//       "image": "https://online.advancedcomputers.co.in/assets/images/altiuslogomini.png",
//       // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
//       "handler": function (response){
//           // alert(response.razorpay_payment_id);
//           // alert(response.razorpay_order_id);
//           // alert(response.razorpay_signature);

//           // this.afs.collection('Orders').doc(this.date2).set(
//           //   {
//           //     routebillid: this.routinebillid + 1,
//           //   },
//           //   { merge: true }
//           // )
        

//       },
//       "prefill": {
//           "name": "Gaurav Kumar",
//           "email": "gaurav.kumar@example.com",
//           "contact": "9999999999"
//       },
//       "notes": {
//           "address": "Corporate Office"
//       },
//       "theme": {
//           "color": "#3399cc"
//       }
//     }
//   );
//   this.rzpl.open();
// }







   pay(){
    //this.loadaddresses.pipe(take(1)).subscribe(value=>alert(JSON.stringify(value[0])));
  this.currentorderid='';
  let oid =this.afs.createId();
  this.currentorderid=oid;

        this.afs.collection('Orders').doc(this.currentorderid).collection('productdetails').doc(this.loadbuynowproduct[0].KEYID).set(
            { 
              'oid':oid,             
              'pid':this.loadbuynowproduct[0].KEYID,
              'images':this.loadbuynowproduct[0].images,         
              'bcc':this.loadbuynowproduct[0].bcc,
              'brand':this.loadbuynowproduct[0].brand, 
              'category':this.loadbuynowproduct[0].category, 
              'code':this.loadbuynowproduct[0].code, 
              'color':this.loadbuynowproduct[0].color,     
              'size':this.loadbuynowproduct[0].size,      
              'sizechart':this.loadbuynowproduct[0].sizechart,
              'gst':this.loadbuynowproduct[0].gst,     
              'igst':this.loadbuynowproduct[0].igst,   
              'hsncode':this.loadbuynowproduct[0].hsncode,    
              'modelno':this.loadbuynowproduct[0].modelno,
              'rate':this.loadbuynowproduct[0].rate,  
              'onlinerate':this.loadbuynowproduct[0].onlinerate,     
              'qnty':this.buynowcalculations[this.buynowcalculations.findIndex((obj => obj.KEYID == this.loadbuynowproduct[0].KEYID))].qnty
            },{merge:true}
          )
  
            this.afs.collection('Orders').doc(this.currentorderid).collection('paymentdetails').add(
              { 
                'oid':oid,
                'paymentid':'razorpayid',
                'price':this.onlineratesum,
                'discount':this.discountsum,
                'rewards':Number(0),
                'deliverycharges':this.deliverychargesum,
                'totalamount':this.totalamount
              }
                )
            .then(()=>{
              this.loadaddresses.pipe(take(1)).subscribe(value=>
                this.afs.collection('Orders').doc(this.currentorderid).collection('userdetails').add(
                { 
                  'oid':oid,
                  'uid':this.currentcustid,
                  'name':JSON.stringify(value[0].fullname).slice(1,-1),
                  'phonenumber':JSON.stringify(value[0].mobile).slice(1,-1),
                  'deliveryaddress':JSON.stringify(value[0].address).slice(1,-1)+' '+JSON.stringify(value[0].landmark).slice(1,-1)+' '+JSON.stringify(value[0].locality).slice(1,-1)+' '+JSON.stringify(value[0].district).slice(1,-1)+' '+JSON.stringify(value[0].state).slice(1,-1)+'-'+JSON.stringify(value[0].pincode).slice(1,-1),
                  'addresstype':JSON.stringify(value[0].addresstype).slice(1,-1),
                }
                  )
              )
              }).then(()=>{
                this.afs.collection('Orders').doc(this.currentorderid).collection('trackingdetails').add(
                  {
                    'KEYID':'',//NEEDSTOINSERt                     
                    'oid':oid,
                    'placed':firebase.firestore.FieldValue.serverTimestamp(),
                    'processed':'',
                    'dispatched':'',
                    'shipped':'',
                    'shipmentexpecteddate':'',//NEEDSTOINSERt
                    'deliveryexpecteddate':'',//NEEDSTOINSERt
                    'trackingnumber':'',
                    'slug':'',
                    'outfordelivery':'',
                    'delivered':'',
                  }
                    )
                }).then(()=>{
                  this.afs.collection('users').doc(this.currentcustid).set({
                    ordered:firebase.firestore.FieldValue.arrayUnion(oid)
                    },{merge:true})
                  })  

}
signout(){
  firebase.auth().signOut().then(()=>{
    this.router.navigate(
      ['sign-in']);
  })
}
}
