import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnimationDurations } from '@angular/material';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material";

@Component({
  selector: 'app-brandscategories',
  templateUrl: './brandscategories.component.html',
  styleUrls: ['./brandscategories.component.css']
})
export class BrandscategoriesComponent implements OnInit {

  public BForm: FormGroup;
  public CForm: FormGroup;

  brandnew: any;
  categorynew: any;
  brcollection: any;
  ctcollection: any;
  selectedbrand: any;
  selectedcategory: any;
  selectedbrand2: any;
  selectedcategory2: any;
  brcollection2: any;
  ctcollection2: any;

  sizy:string;
  sizes = [{label:'DEFAULT',checked:true}, {label:'MM',checked:false}, {label:'MB',checked:false}, {label:'GB',checked:false}, {label:'TB',checked:false}, {label:'INCHES',checked:false}, {label:'MP',checked:false}];
  
  sizenameDEFAULT: string='DEFAULT';
  sizenameMM: string='0';
  sizenameMB: string='0';
  sizenameGB: string='0';
  sizenameTB: string='0';
  sizenameINCHES: string='0';
  sizenameMP: string='0';

  updateCheckedOptions(index, size) {

    size.checked = !size.checked;
    //----newly added--------
    size.checked=true;
    //----newly added--------
    //console.log(index,size.checked);

    if(size.checked==true)
    {
    if(index==0){this.sizenameDEFAULT='DEFAULT';this.sizenameMM='0';this.sizenameMB='0';this.sizenameGB='0';this.sizenameTB='0';this.sizenameINCHES='0';this.sizenameMP='0';this.sizes = [{label:'DEFAULT',checked:true}, {label:'MM',checked:false}, {label:'MB',checked:false}, {label:'GB',checked:false}, {label:'TB',checked:false}, {label:'INCHES',checked:false}, {label:'MP',checked:false}];}
    if(index==1){this.sizenameDEFAULT='0';this.sizenameMM='MM';this.sizenameMB='0';this.sizenameGB='0';this.sizenameTB='0';this.sizenameINCHES='0';this.sizenameMP='0';this.sizes = [{label:'DEFAULT',checked:false}, {label:'MM',checked:true}, {label:'MB',checked:false}, {label:'GB',checked:false}, {label:'TB',checked:false}, {label:'INCHES',checked:false}, {label:'MP',checked:false}];}
    if(index==2){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameMB='MB';this.sizenameGB='0';this.sizenameTB='0';this.sizenameINCHES='0';this.sizenameMP='0';this.sizes = [{label:'DEFAULT',checked:false}, {label:'MM',checked:false}, {label:'MB',checked:true}, {label:'GB',checked:false}, {label:'TB',checked:false}, {label:'INCHES',checked:false}, {label:'MP',checked:false}];}
    if(index==3){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameMB='0';this.sizenameGB='GB';this.sizenameTB='0';this.sizenameINCHES='0';this.sizenameMP='0';this.sizes = [{label:'DEFAULT',checked:false}, {label:'MM',checked:false}, {label:'MB',checked:false}, {label:'GB',checked:true}, {label:'TB',checked:false}, {label:'INCHES',checked:false}, {label:'MP',checked:false}];}
    if(index==4){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameMB='0';this.sizenameGB='0';this.sizenameTB='TB';this.sizenameINCHES='0';this.sizenameMP='0';this.sizes = [{label:'DEFAULT',checked:false}, {label:'MM',checked:false}, {label:'MB',checked:false}, {label:'GB',checked:false}, {label:'TB',checked:true}, {label:'INCHES',checked:false}, {label:'MP',checked:false}];}
    if(index==5){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameMB='0';this.sizenameGB='0';this.sizenameTB='0';this.sizenameINCHES='INCHES';this.sizenameMP='0';this.sizes = [{label:'DEFAULT',checked:false}, {label:'MM',checked:false}, {label:'MB',checked:false}, {label:'GB',checked:false}, {label:'TB',checked:false}, {label:'INCHES',checked:true}, {label:'MP',checked:false}];}
    if(index==6){this.sizenameDEFAULT='0';this.sizenameMM='0';this.sizenameMB='0';this.sizenameGB='0';this.sizenameTB='0';this.sizenameINCHES='0';this.sizenameMP='MP';this.sizes = [{label:'DEFAULT',checked:false}, {label:'MM',checked:false}, {label:'MB',checked:false}, {label:'GB',checked:false}, {label:'TB',checked:false}, {label:'INCHES',checked:false}, {label:'MP',checked:true}];}

    //console.log(this.sizes);
    }
    if(size.checked==false){
      //console.log(index,size);

    }
   
}

  constructor(public snackBar: MatSnackBar,private afs: AngularFirestore, public fb: FormBuilder) { }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
    });
 } 

  ngOnInit() {

    this.FormB();
    this.FormC();

    this.brcollection =[];
    this.brcollection = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );
    
    this.ctcollection =[];
    this.ctcollection = this.afs.collection('Categories',ref => ref.orderBy('allcategories','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );


    this.brcollection2 =[];
    this.brcollection2 = this.afs.collection('Brands').snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

  }

   // Reactive particular form
   FormB() {
    this.BForm = this.fb.group({
      newbrand: [''],
    })
  }
  // Accessing form control using getters
  get newbrand() {
    return this.BForm.get('newbrand');
  }

// Reactive particular form
FormC() {
  this.CForm = this.fb.group({
    newcategory: [''],
  })
}
// Accessing form control using getters
get newcategory() {
  return this.CForm.get('newcategory');
}

bsubmitbtn(){
  this.afs.collection('Brands').doc(String(this.brandnew).toUpperCase()).set(
    {
      brandname : String(this.brandnew).toUpperCase()
    },
     {merge: true}
)
}

bdeletebtn(brndname){
  this.afs.collection('Brands').doc(brndname).delete().then(function() {
    //console.log("Document successfully deleted!");
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
}

csubmitbtn(){
  this.afs.collection('Categories').doc('allcategories').set(
    {
      allcategories:firebase.firestore.FieldValue.arrayUnion(String(this.categorynew).toUpperCase()),
      // committed:'', 
    },
     {merge: true}
)
}

cdeletebtn(ctname){
  this.afs.collection('Categories').doc('allcategories').update({
    allcategories:firebase.firestore.FieldValue.arrayRemove(ctname)
  })
}

changeBrand(brandselected){
this.selectedbrand=brandselected;
}
changeCategory(categoryselected){
this.selectedcategory=categoryselected;
}

bccommitbtn(br,ct,szy){
  if(szy != undefined){
  this.afs.collection('BrandCategoryCounter').get().toPromise().then( querySnapshot => {
    querySnapshot.forEach( doc => {

      let a=doc.data().count;
      //console.log(a)

this.afs.collection('Brands').doc(br).get().toPromise().then(doc => {
if(!doc.data().categories){
  this.afs.collection('Brands').doc(br).update({
    categories:firebase.firestore.FieldValue.arrayUnion({sizechart:String(szy).toUpperCase(),categoryname:String(ct).toUpperCase(),id:a})
  }).then(()=>{

  this.afs.collection('Categories').doc('allcategories').update({
    committed:firebase.firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
  }).then(()=>{
  
    this.afs.collection('BrandCategoryCounter').doc('Counter').update({
      count: firebase.firestore.FieldValue.increment(1)
    })
   })
  })

}
var arr=[];    
if(doc.data().categories){
  for(let i=0;i<doc.data().categories.length;i++){
    if(doc.data().categories[i].categoryname == ct){
      arr.push(ct)
      //console.log("ALREADY COMMITTED")
      this.openSnackBar('Committed Already', 'OK')
    }
    if(doc.data().categories[i].categoryname != ct){
    }
}

if(arr.length==0){
this.afs.collection('Brands').doc(br).update({
  categories:firebase.firestore.FieldValue.arrayUnion({sizechart:String(szy).toUpperCase(),categoryname:String(ct).toUpperCase(),id:a}) 
}).then(()=>{

this.afs.collection('Categories').doc('allcategories').update({
  committed:firebase.firestore.FieldValue.arrayUnion(String(ct).toUpperCase()) 
}).then(()=>{

  this.afs.collection('BrandCategoryCounter').doc('Counter').update({
    count: firebase.firestore.FieldValue.increment(1)
  })
 })
})
//console.log("NEWLY COMMITTED")
this.openSnackBar('Committed Successfully', 'OK')
    }
  }

})

})

  })
  }
  else{
    this.openSnackBar('Please Select Size Chart', 'OK')
  }
}


changeBrand2(brandselected){
  this.selectedbrand2=brandselected;
    
  this.ctcollection2 =[];
  this.ctcollection2 = this.afs.collection('Brands',ref => ref.where('brandname', "==" , brandselected)).snapshotChanges()
  .pipe(
    map(actions => actions.map(a => a.payload.doc.data()))
  );

  }

  changeCategory2(brandselected,categoryselected){
  this.selectedcategory2=categoryselected;

  }



  
}