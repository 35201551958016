import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.css']
})
export class SportsComponent implements OnInit {

  bigimage:any;
  prdcode:any;
  imagelistclick(i,j){
    console.log(i,j)
     console.log(this.products[i])
this.bigimage=this.products[i].images[j];

this.prdcode = this.products[i].code;
  }

  products: any[]=[];

  constructor(private afs: AngularFirestore) {

  this.afs.collection('Products')
  .get().toPromise()
  .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        this.products.push(doc.data());
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  })
  }
  ngOnInit() {
    
  }

}
