import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

import { MatDialog } from '@angular/material/dialog';
import {Removeitemdialog} from '../checkout/removeitem-dialog.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})


export class CartComponent implements OnInit {

  currentcustid: string;
  loadcartproducts: any[]= []; //declare before constructor
  loadcart: any;
  loadsavelater: any;
  loadsavelaterproducts: any[]= []; //declare before constructor
  
  cartscollection: any;
  
  deliverydate: Date;

  selectedaddress: any;
  loadaddresses: any;

  cartcalculations: any[]= []; //declare before constructor
  ratesum: number;
  onlineratesum: number;
  discountsum: number;
  deliverychargesum: number;
  deliverydaysmax: number;
  totalamount: number;

  constructor(private dialog: MatDialog, private fb: FormBuilder, public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private afs: AngularFirestore, private http:HttpClient, public router: Router) {
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }
  addorreplace(array, item){
    const i=array.findIndex(_item => _item.KEYID===item.KEYID);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

   addorreplace2(array, item){
    const i=array.findIndex(_item => _item.prdid===item.prdid);
    if(i>-1) array[i] =item;
    else array.push(item);
   }

  ngOnInit() { 

//     this.cartscollection = this.afs.collection('Carts', ref => ref.where("uid", "==" ,this.currentcustid)).snapshotChanges()      
// .pipe( 
//   map(actions => actions.map(a => a.payload.doc.data()))
// );

    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;

          this.cartscollection = this.afs.collection('Carts', ref => ref.where("uid", "==" ,this.currentcustid)).snapshotChanges()      
.pipe( 
  map(actions => actions.map(a => a.payload.doc.data()))
);

this.loadcart =[];
this.cartcalculations =[];
this.loadsavelater =[];
this.afs.collection("Carts", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
  if (!querySnapshot.empty) {
    querySnapshot.forEach(doc => {
      if(doc.data().iscart==true){
      this.addorreplace2(this.loadcart,({'prdid':doc.data().pid, 'qnty':doc.data().qnty}));
      console.log(this.loadcart)
      }
      if(doc.data().iscart==false){
        this.addorreplace2(this.loadsavelater,({'prdid':doc.data().pid, 'qnty':doc.data().qnty}));
        console.log(this.loadsavelater)
        }
    })
  }
}).then(()=>{
  this.loadcartproducts =[];
  for(let i=0;i < this.loadcart.length;i++){
  this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadcart[i].prdid )).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.addorreplace(this.loadcartproducts,doc.data());

        this.afs.collection("Carts", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(docz => {
              if(docz.data().pid==doc.data().KEYID){
                this.addorreplace(this.cartcalculations,{'KEYID':doc.data().KEYID,'rate':doc.data().rate,'onlinerate':doc.data().onlinerate,'qnty':docz.data().qnty,
                'discount': (doc.data().rate > doc.data().onlinerate) ? (doc.data().rate - doc.data().onlinerate) : 0,
                'deliverycharges': (doc.data().deliverycharges) ? (doc.data().deliverycharges) : 0,
                'deliverydays': (doc.data().deliverydays) ? Number(doc.data().deliverydays) : (5) })
              }
            })
          }
        }).then(()=>{
          this.ratesum=Number(this.cartcalculations.map(o => (o.rate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.onlineratesum=Number(this.cartcalculations.map(o => (o.onlinerate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.discountsum=Number(this.cartcalculations.map(o => (o.discount*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.deliverychargesum=Number(this.cartcalculations.map(o => (o.deliverycharges)).reduce((a,c)=> {return a+c}).toFixed(0));
          this.deliverydaysmax=Number(this.cartcalculations.map(o => (o.deliverydays)).reduce((a,c)=> {return Math.max(a,c)}).toFixed(0));
          this.deliverydate=new Date(Date.now() +  (24*3600*1000*this.deliverydaysmax) );
          this.totalamount=Number((this.ratesum - this.discountsum + this.deliverychargesum).toFixed(0));
        })

        console.log(this.cartcalculations)
      })
    }
})
  }

  this.loadsavelaterproducts =[];
  for(let i=0;i < this.loadsavelater.length;i++){
  this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadsavelater[i].prdid )).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.addorreplace(this.loadsavelaterproducts,doc.data());
      })
    }
})
  }




  this.loadaddresses =[];
  this.loadaddresses=this.afs.collection("users").doc(this.currentcustid).collection("addresses",ref=>ref.orderBy('timestamp','asc')).snapshotChanges()
    .pipe(
      map(actions => actions.map(a => a.payload.doc.data()))
    );

    this.selectedaddress =[];

  this.afs.collection("users").doc(this.currentcustid).collection("addresses", ref=> ref.where("primaryaddress","==",true)).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.selectedaddress=doc.data().KEYID;
      })
    }
  })



})


    }
    else{
      this.router.navigate(
        ['sign-in'], 
        {
          queryParams: { redirectURL: this.router.url } 
        });
    }
})


  }

  updateCheckedOptions(a,i){
    console.log("called")
    this.afs.collection("users").doc(this.currentcustid).collection("addresses", ref=> ref.where("KEYID","==",a)).get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          this.selectedaddress=doc.data().KEYID;
          this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(String(doc.data().KEYID)).update({
            primaryaddress: true
        });
        })
      }
    }).then(()=>{
    this.afs.collection("users").doc(this.currentcustid).collection("addresses").get().toPromise().then(querySnapshot => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          if(this.selectedaddress!=doc.data().KEYID){
            this.afs.collection("users").doc(this.currentcustid).collection("addresses").doc(String(doc.data().KEYID)).update({
            primaryaddress: false
        });
      }
        })
      }
    })
  })


  }



  qntyminusclicked(cid,pid,qnty){
    this.afs.collection("Carts").doc(cid).update({
      qnty: qnty ? Number(qnty)-1 : 1,
    }).then(()=>{
      this.cartcalculations[this.cartcalculations.findIndex((obj => obj.KEYID == pid))].qnty = this.cartcalculations[this.cartcalculations.findIndex((obj => obj.KEYID == pid))].qnty-1
      
      this.ratesum=Number(this.cartcalculations.map(o => (o.rate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.onlineratesum=Number(this.cartcalculations.map(o => (o.onlinerate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.discountsum=Number(this.cartcalculations.map(o => (o.discount*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverychargesum=Number(this.cartcalculations.map(o => (o.deliverycharges)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverydaysmax=Number(this.cartcalculations.map(o => (o.deliverydays)).reduce((a,c)=> {return Math.max(a,c)}).toFixed(0));
      this.deliverydate=new Date(Date.now() +  (24*3600*1000*this.deliverydaysmax) );
      this.totalamount=Number((this.ratesum - this.discountsum + this.deliverychargesum).toFixed(0));

      console.log(this.cartcalculations)
    })  }
  qntyplusclicked(cid,pid,qnty){
    this.afs.collection("Carts").doc(cid).update({
      qnty: qnty ? Number(qnty)+1 : 2,
    }).then(()=>{
      this.cartcalculations[this.cartcalculations.findIndex((obj => obj.KEYID == pid))].qnty = this.cartcalculations[this.cartcalculations.findIndex((obj => obj.KEYID == pid))].qnty+1
      
      this.ratesum=Number(this.cartcalculations.map(o => (o.rate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.onlineratesum=Number(this.cartcalculations.map(o => (o.onlinerate*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.discountsum=Number(this.cartcalculations.map(o => (o.discount*o.qnty)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverychargesum=Number(this.cartcalculations.map(o => (o.deliverycharges)).reduce((a,c)=> {return a+c}).toFixed(0));
      this.deliverydaysmax=Number(this.cartcalculations.map(o => (o.deliverydays)).reduce((a,c)=> {return Math.max(a,c)}).toFixed(0));
      this.deliverydate=new Date(Date.now() +  (24*3600*1000*this.deliverydaysmax) );
      this.totalamount=Number((this.ratesum - this.discountsum + this.deliverychargesum).toFixed(0));
    
      console.log(this.cartcalculations)
    })
  }
  
  saveforlater(cid,iscart,i){
    this.afs.collection("Carts").doc(cid).update({
      iscart: !iscart,
    }).then(()=>{

this.ngOnInit();

if(this.cartcalculations.length<1){
  var el =document.getElementsByClassName('sidebarzz') as HTMLCollectionOf<HTMLElement>;
  el[0].style.cssText="display:none !IMPORTANT;"
  }
  else{
    var el =document.getElementsByClassName('sidebarzz') as HTMLCollectionOf<HTMLElement>;
    el[0].style.cssText="display:block !IMPORTANT;"
  }

  
    })
  }


  // remove(cid){
  //   this.afs.collection("Carts").doc(cid).delete()
  //   .then(()=>{
  //     if(this.loadcartproducts.length>0){
  //     this.ngOnInit()
  //     }else{
  //       this.router.navigate(['/home']);
  //     }
  //   })
  // }
  remove(cid){
    
    this.openDialog(cid);
  }
  openDialog(cid) {
    const dialogRef = this.dialog.open(Removeitemdialog,{
      data:{
        message: 'Are you sure you want to remove this item?',
        buttonText: {
          ok: 'REMOVE',
          cancel: 'CANCEL'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.afs.collection("Carts").doc(cid).delete().then(()=>{
          this.ngOnInit()
          if(this.loadcartproducts.length==1){
          var el =document.getElementsByClassName('sidebarzz') as HTMLCollectionOf<HTMLElement>;
          el[0].style.cssText="display:none !IMPORTANT;"
          }
            })
          }
    });
  }

  gotocart(){
    this.router.navigate(['/cart'])
  }


  navigatecheckout(){
    this.router.navigate(
      ['checkout']);
  }
  navigateprd(prdid){
    this.router.navigate(
      ['productdescription'], 
      {
        queryParams: {myprParam: JSON.stringify(prdid) } ,
        // queryParamsHandling: 'merge'
      });
  }
  navigatehome(){
    this.router.navigate(
      ['home']);
  }
}
