import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import {DatePipe} from '@angular/common';

// import { AppRoutingModule } from './app-routing.module';
import { AppRoutingModule } from './shared/routing/app-routing.module';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { FlexLayoutModule } from '@angular/flex-layout';

import { QRCodeModule } from 'angularx-qrcode';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HttpClientModule } from '@angular/common/http';
import { MatBadgeModule, MatMenuModule, MatTabsModule, MatExpansionModule, MatTooltipModule, MatChipsModule, MatSnackBarModule, MatCardModule, MatIconModule, MatTableModule, MatSortModule, MatPaginatorModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatRadioModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatCheckboxModule, MatListModule, MatProgressBarModule, MatGridListModule, MatStepperModule, MatSidenavModule, MatToolbarModule, MatBottomSheetModule, MatButtonToggleModule} from '@angular/material';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatTableExporterModule } from 'mat-table-exporter';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';
import { HomeComponent } from './home/home.component';
import { GroupByPipe } from 'src/assets/shared/sharedpipes/group-by.pipe';
import { NumberToWordsPipe } from 'src/assets/shared/sharedpipes/number-to-words.pipe';

import {NgPipesModule} from 'ngx-pipes';
import { AvatarModule } from 'ngx-avatar';
import { SportsComponent } from './sports/sports.component';
import { ProductdescriptionComponent } from './productdescription/productdescription.component';
import { ProductdescriptionService } from 'src/assets/services/productdescription.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SortPipe } from 'src/assets/shared/sharedpipes/sort.pipe';
import { ProductcategoryComponent } from './productcategory/productcategory.component';
import { ProductfilterComponent } from './productfilter/productfilter.component';
import { filterproduct } from 'src/assets/shared/sharedpipes/filterproduct.pipe';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
// Auth service
import { AuthService } from "./shared/services/auth.service";
import { OverlayModule } from '@angular/cdk/overlay';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { MyordersComponent } from './myorders/myorders.component';
import { CartComponent } from './cart/cart.component';
import { HeaderComponent } from './header/header.component';
import { NgxStarsModule } from 'ngx-stars';
import { RatingsandreviewsComponent } from './ratingsandreviews/ratingsandreviews.component';
import { IndianCurrency } from 'src/assets/shared/sharedpipes/indianCurrency.pipe';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { DateSuffixPipe } from 'src/assets/shared/sharedpipes/datesuffix.pipe';
import { CenterMatmenuDirective } from './header/center-matmenu.directive';
import { WishlistComponent } from './wishlist/wishlist.component';
import { NgxSlickJsModule } from "ngx-slickjs";
import { DateAgoPipe } from 'src/assets/shared/sharedpipes/date-ago.pipe';
import { AllreviewsComponent } from './allreviews/allreviews.component';
import { AllrecentlyviewedComponent } from './allrecentlyviewed/allrecentlyviewed.component';
import { BuynowComponent } from './buynow/buynow.component';
import { FooterComponent } from './footer/footer.component';
import { FooterminiComponent } from './footermini/footermini.component';
import { Globals } from 'src/assets/shared/globals';
import { WindowService } from './components/sign-up/window.service';
import { Removeitemdialog } from './checkout/removeitem-dialog.component';
import { ChangePassworddialog } from './myprofile/change-password.component';
import { ChangeEmaildialog } from './myprofile/change-email.component';
import { ChangeMobiledialog } from './myprofile/change-mobile.component';
import { BrandscategoriesComponent } from './admin/brandscategories/brandscategories.component';
import { ProductbuildComponent } from './admin/productbuild/productbuild.component';


var config = {
  apiKey: "AIzaSyAYQNAp7u75niyptsUCTFr9Rj10cNfuxW4",
  authDomain: "advcompecomdl.firebaseapp.com",
  projectId: "advcompecomdl",
  storageBucket: "advcompecomdl.appspot.com",
  messagingSenderId: "575508799278",
  appId: "1:575508799278:web:0490ca647c883a41b164bf",
  measurementId: "G-8B6NS2M3P4"
};

enableProdMode();

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    QRCodeModule,
    AngularFontAwesomeModule,
    OverlayModule,
    NgxSlickJsModule.forRoot({
      links: {
        jquery: "https://code.jquery.com/jquery-3.4.0.min.js",
        slickJs:
          "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js",
        slickCss:
          "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css",
        slickThemeCss: null // if you are set null, this css won't load
      }
    }),
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatBadgeModule,
    MatMenuModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatChipsModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule, 
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule,
    MatGridListModule,
    MatCarouselModule.forRoot(),
    MatTableExporterModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    NgxPrintModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireDatabaseModule, //database
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    NgPipesModule,
    AvatarModule,
    LazyLoadImageModule,
    NgxStarsModule
    ],
  providers: [WindowService,Globals,AuthService,MatDatepickerModule,DatePipe,ProductdescriptionService],
  entryComponents: [HeaderComponent,Removeitemdialog, ChangePassworddialog,ChangeEmaildialog,ChangeMobiledialog],
  declarations: [ DateAgoPipe, DateSuffixPipe,IndianCurrency, filterproduct, SortPipe, NumberToWordsPipe, GroupByPipe, AppComponent, HomeComponent, SportsComponent, ProductdescriptionComponent, ProductcategoryComponent, ProductfilterComponent, DashboardComponent, SignInComponent, SignUpComponent, ForgotPasswordComponent, VerifyEmailComponent, MyprofileComponent, MyordersComponent, CartComponent, HeaderComponent, CenterMatmenuDirective, RatingsandreviewsComponent, CheckoutComponent, OrderdetailsComponent, WishlistComponent, AllreviewsComponent, AllrecentlyviewedComponent, BuynowComponent, FooterComponent, FooterminiComponent, Removeitemdialog, ChangePassworddialog, ChangeEmaildialog,ChangeMobiledialog, BrandscategoriesComponent, ProductbuildComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }

