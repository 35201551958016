import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})

export class WishlistComponent implements OnInit {
  
  currentcustid: string;
  loadwishlistproducts: any[]= []; //declare before constructor
  loadwishlist: any;
  addorreplace(array, item){
    const i=array.findIndex(_item => _item.KEYID===item.KEYID);
    if(i>-1) array[i] =item;
    else array.push(item);
   }


  constructor(private ren: Renderer2, public snackBar: MatSnackBar, public afAuth: AngularFireAuth, private afs: AngularFirestore, private http:HttpClient, public router: Router) {
    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      }
    })

  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 2000,
       panelClass: ['snackbarcss'],
    });
    }


  ngOnInit() {

    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;

this.loadwishlist =[];
this.afs.collection("users", ref => ref.where("uid", "==" ,this.currentcustid)).get().toPromise().then(querySnapshot => {
  if (!querySnapshot.empty) {
    querySnapshot.forEach(doc => {
      this.loadwishlist=doc.data().wishlist
      console.log(this.loadwishlist)
    })
  }
}).then(()=>{
  this.loadwishlistproducts =[];
  for(let i=0;i < this.loadwishlist.length;i++){
  this.afs.collection("Products", ref=> ref.where("KEYID", "==",this.loadwishlist[i] )).get().toPromise().then(querySnapshot => {
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        this.addorreplace(this.loadwishlistproducts,doc.data());
      })
    }
})
  }
})
      }
      else{
        this.router.navigate(
          ['sign-in'], 
          {
            queryParams: { redirectURL: this.router.url } 
          });
      }
})


  }
  remove(pid){
    this.afAuth.authState.subscribe(user=>{
      if(user){
      this.currentcustid=firebase.auth().currentUser.uid;
      console.log(this.currentcustid)
    this.afs.collection('users').doc(this.currentcustid).set({
      wishlist:firebase.firestore.FieldValue.arrayRemove(pid)
      },{merge:true}).then(()=>{
        this.afs.collection('Products').doc(pid).set({
          wishlist:firebase.firestore.FieldValue.arrayRemove(this.currentcustid)
          },{merge:true})
      })
    }
  })
  }
  productclicked(prdid){
    this.router.navigate(
      ['productdescription'], 
      {
        queryParams: {myprParam: JSON.stringify(prdid) } ,
        // queryParamsHandling: 'merge'
      });
  }
}
